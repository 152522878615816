@charset "UTF-8";

.subscribeNewsletter.variant1 table {background:none;}
.subscribeNewsletter.variant1 label,
.subscribeNewsletter.variant1 .question {font-size:1rem;}
.subscribeNewsletter.variant1 .field.radio table{border:0;margin:0;}
.subscribeNewsletter.variant1 .field.radio table td{padding:rem-calc(10) 0;border:none;}
.subscribeNewsletter.variant1 input[type=submit]{@include button;}
.subscribeNewsletter.variant1 .field.captcha {margin:$margin-normal 0;}
.subscribeNewsletter.variant1 .field.captcha > div:first-child,
.subscribeNewsletter.variant1 #input_container_refreshcaptchaimage {float:left;}
.subscribeNewsletter.variant1 #input_container_refreshcaptchaimage input {font-family:'FontAwesome';border:0;background:#ededed;height:50px;color:$black;border-radius:0;margin:0;}
.subscribeNewsletter.variant1 #captcha_description_container {display:none;}
.subscribeNewsletter.variant1 #input_container_captchatextbox {clear:left;width:233px;}
/*.subscribeNewsletterVariant1 #input_container_refreshcaptchaimage input {background:#ededed;border:0;width:200px;color:#000;border-radius:0;margin:0;}*/
.subscribeNewsletter.variant1 {
  .mailinglists.optin {
    font-family:"Source Sans Pro",sans-serif;
    label {cursor:pointer;}
    ul {margin:0 0 .5rem 0;padding:0;}
    li {list-style:none;margin:0 0 .25rem 0;}
    ul ul {margin:0 0 0 1rem;padding-top:0;}
    ul li ul li {position:relative;
      &:first-child {margin:.25rem 0;}
    }
    ul ul ul li {display:none;}
    ul ul ul.open li {display:block;}
    ul li ul label + ul,
    ul li ul label + div + ul {
      display:block;
    }
    ul li ul label + ul:before,
    ul li ul label + div + ul:before {content:"+"; cursor:pointer; font-weight: 700; left:-.9rem; position:absolute; text-align: center; top:-.1rem; width:1rem;}
    ul li ul label + ul.open:before,
    ul li ul label + div + ul.open:before {content:"-";}
    > ul {padding:0 0 1rem 0;}
    .remark {display:inline-block;position:relative;
      &:before {background: #5998d3; border-radius: 50%; color: #fff; content: "i"; cursor:pointer; font-weight: 700; margin-left: .5rem; padding: 0 .5rem; position: relative; width: 2rem;}
      &:hover p {background: #ccc; display:inline-block; font-style: italic; font-size: .825rem; margin: 0 .5rem; width:16rem; padding: 0 1rem; position: absolute; z-index:999;}
      p {display: none; padding:0; margin:0;
        span {}
      }
    }
    input[type="submit"] {@include button;}
    input[type="submit"]:first-child {margin-right: 1rem;}
    #Form_Opt_In_Opt_Out_Buttons {overflow:hidden;}
  }
}
// content:"\f021"